import Head from "next/head";
import Router from "next/router";
import Link from "next/link";
import axios from "axios";
import { useContext, useState } from "react";
import { LockFilled } from "@ant-design/icons";

import AppContext from "../src/common/_context/AppContext";
import { Row, Col, Form, Input, Button, Checkbox, Space } from "antd";

import styles from "../src/styles/pages/Login.module.scss";

export default function Login() {
  const [form] = Form.useForm();
  const [store, setStore] = useContext(AppContext);
  const [state, setState] = useState({
    loading: false,
    error: false,
  });

  const onFormSubmit = (values) => {
    setState({ ...state, loading: true });

    const formData = {
      username: values.username,
      password: values.password,
    };

    axios
      .post(
        `${process.env.NEXT_PUBLIC_API_DOMAIN_URL}/wp-json/jwt-auth/v1/token`,
        formData
      )
      .then((res) => {
        console.log(res);
        setState({ ...state, loading: false });
        localStorage.setItem("token", JSON.stringify(res.data));

        setStore({
          ...store,
          token: res.data,
        });
        Router.push("/accueil");
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          if ("[jwt_auth] invalid_username" === error.response.data.code) {
            form.setFields([
              {
                name: "username", //values.email,
                value: values.username,
                errors: ["Aucun utilisateur ne correspond sur ce site"],
              },
            ]);
          }
          if ("[jwt_auth] incorrect_password" === error.response.data.code) {
            form.setFields([
              {
                name: "password", //values.email,
                value: values.password,
                errors: ["Mot de passe incorrect"],
              },
            ]);
          }
        }
        setState({ ...state, loading: false });
      });
  };

  const handleOnchange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Head>
        <title>Nateo | Login</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div className={styles.page}>
        <div className={styles.bg}>
          <img
            src="/img/login-bg.jpg"
            alt="Background image"
            className={styles.bg_img}
          />
        </div>
        <div className={styles.login}>
          <div className={styles.header}>
            <img
              src="/logo-scaprim.svg"
              alt="Logo Scaprim Nateo"
              className={styles.header_logo}
            />
            <img src="/logo-nateo.svg" alt="Logo Scaprim Nateo" width={150} />
          </div>
          <Form
            form={form}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFormSubmit}
            className={styles.form}
          >
            <h1 className={styles.form_title}>Se connecter</h1>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Veuillez saisir votre identifiant",
                },
              ]}
            >
              <Input placeholder="Votre identifiant" prefix={<>@</>} />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Veuillez saisir votre mot de passe",
                },
              ]}
            >
              <Input.Password
                placeholder="Votre mot de passe"
                prefix={<LockFilled />}
              />
            </Form.Item>
            <div className={styles.forgetPwdLinkCtn}>
              <Link href="/mot-de-passe-oublie">
                <a className={styles.forgetPwdLink}>Mot de passe oublié</a>
              </Link>
            </div>
            <Form.Item>
              <Button
                className={styles.form_submit}
                type="primary"
                htmlType="submit"
                loading={state.loading}
              >
                Se connecter
              </Button>
              <p className={styles.form_infos}>
                {`Vous n'avez pas de compte.`}
                <br />
                <a href="mailto:laurence.petiot@scaprimreim.com">{`Demander à l'administrateur du service`}</a>
              </p>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
}
